import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
// import teacherReducer from './reducers/teacherReducer';
// import {fetchUsers} from './action/teacherActions';

// const initialState = {
// 	loading: false,
// 	users: [],
// 	error: ''
// }

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
// store.subscribe(() => {console.log(store.getState())} )
export default store;