import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {baseUrl} from '../../config/config';
import ApiLoader from '../ApiLoader';
import TeacherHeader from './TeacherHeader';
import Footer from '../Footer';

function TeacherTermsCondition() {
	const [data, setData] = useState([])
	const [loader, setLoader] = useState(true);
	useEffect(() => {
		const token = localStorage.getItem('token');
		axios.get(baseUrl+'page/terms-and-conditions', {headers: {"Authorization" : `Bearer ${token}`} })
		.then((res) => {
			setLoader(false);
			setData(res.data);
    //   console.log(res.data);
		})
	}, [])
	return (
		<>
			<TeacherHeader/>
			<div className="dashboard-cover">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="dashboard-title-cover">
								<h1>Terms & Condition</h1>
							</div>
							<div className="dashboard-body bg-white">
								<div className="content-box">
								
								{loader && <ApiLoader />}		
								{loader
								?
									null
								:
									data?.data?.description
									? <div dangerouslySetInnerHTML={{__html:data?.data?.description}}></div>
									: <div className="RecordNotFound"> No Record Found</div>
								}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default TeacherTermsCondition
