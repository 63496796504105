import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../config/config';
import ApiLoader from '../ApiLoader';
import TeacherHeader from './TeacherHeader';
import Footer from '../Footer';

function TeacherSubMenuResources() {
	let {resource} = useParams();
	const [data, setData] = useState([]);
	const [loader, setLoader] = useState(true);
	useEffect(() => {
		setLoader(true);
		const token = localStorage.getItem('token');
		axios.get(baseUrl + `page/${resource}`, { headers: { "Authorization": `Bearer ${token}` } })
			.then((res) => {
				setLoader(false);
				setData(res.data);
				// console.log(res.data.data.title);
			})
	}, [resource])

	// console.log(resource)
	return (
		<>
			<TeacherHeader />
			<div className="dashboard-cover">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="dashboard-title-cover">
								<h1>{data?.data?.title}</h1>
							</div>
							<div className="dashboard-body bg-white">
								<div className="content-box">

									{loader && <ApiLoader />}

									{loader
										?
										null
										:
										data?.data?.description
											? <div dangerouslySetInnerHTML={{ __html: data?.data?.description }}></div>
											: <div className="RecordNotFound"> No Record Found</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default TeacherSubMenuResources

