import React, { useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
// import Donut from "react-donut";
// import { PieChart } from 'react-minimal-pie-chart';

function DoughnutChart(props) {
	// const [chartAllData, setChartAllData] = useState();
	// useEffect(() => {
	// 	console.log('####')
	// 	setChartAllData(props.data)
	// },[props.data])

	console.log('all data chart ', props.data)

	const donut = useRef(null);
	// console.log('hello', props?.data?.data?.chartdata);
	// console.log(props?.data?.data?.chartdata?.map(item => item.level))
	// let newChartData = []
	// let chartData = props?.data?.data?.chartdata?.map(item => {
	// 	return {
	// 		name: item.level,
	// 		data: item.total_submission * 100
	// 	}

	// 	// newChartData.push(dataObj)
	// })
	// console.log('new', chartData)
	return (
		<>

			{
				props?.data?.data?.chartdata?.length === 0
					? <p className="donughtError">No Keyword Found For Doughnut Chart</p>
					:
					<div className="doughnut">
						{/* {console.log('Final final', chartAllData)} */}

						{/* data={[
								{ title: 'One', value: 10, color: '#E38627' },
								{ title: 'Two', value: 15, color: '#C13C37' },
								{ title: 'Three', value: 20, color: '#6A2135' },
							]}
						<PieChart
							data={dataMock}
							radius={PieChart.defaultProps.radius - shiftSize}
							segmentsShift={(index) => (index === 0 ? shiftSize : 0.5)}
							label={({ dataEntry }) => dataEntry.value}
							labelStyle={{
							...defaultLabelStyle,
							}}
						/> */}




						{/* <Donut
							chartData={chartAllData}
							// chartData = {[
							// 	{name: "1", data: 26},
							// 	{name: "2", data: 8},
							// 	{name: "3", data: 17},
							// 	{name: "4", data: 9},
							// 	{name: "5", data: 27},
							// 	{name: "6", data: 8},
							// 	// {name:"7", data: 5}
							// ]}
							// chartData={[
							// 	{ name: "Black Panther", data: 33 },
							// 	{ name: "Avengers", data: 35 },
							// 	{ name: "Antman", data: 32 }
							// ]}
							chartWidth={300}
							chartHeight={300}
							// title="Marvel movies that were popular this year"
							chartThemeConfig={{
								series: {
									colors: ['#4055a5', '#499fd6', '#20863c', '#86c34b', '#ffd024', '#f35228']
								}
							}}
						/>  */}


						<Doughnut
							ref={donut}
							data={{
								labels: ['Remember', 'Understand', 'Apply', 'Analyze', 'Evaluate', 'Create'],
								datasets: [{
									data: props?.data?.data?.chartdata?.map(item => (Math.round(item.total_submission * 100) ) ),
									// data: props?.data?.map(item => item.total_submission ),
									// data: props?.data?.map(item => parseFloat((Number(item.total_submission) / Number(props?.totalSubm)) * 100).toFixed(2)),
									// data: props?.data?.map(item => (item.total_submission/props?.totalsubm)*100 ), Total_Submissons Numbers from api
									// data: [0,6,3,0,0,0], 
									backgroundColor: ['#4055a5', '#499fd6', '#20863c', '#86c34b', '#ffd024', '#f35228']

								}]
							}}
							options={{
								responsive: true,
								title: {
									display: false,
									text: 'Chart.js Doughnut Chart'
								},
								tooltips: {
									callbacks: {
										label: function (tooltipItem, data) {
											var currentLabel = data?.labels[tooltipItem.index];
											var dataset = data.datasets[tooltipItem.datasetIndex];
											// var total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
											// 	return previousValue + currentValue;
											// });
											var currentValue = dataset.data[tooltipItem.index] + ' %';
											// var precentage = Math.floor(((currentValue / total) * 100) + 0.5);
											return currentLabel + ': ' + currentValue;
										},
									},
									
								},

								maintainAspectRatio: false,
								//showAllTooltips: true,
								legend: {
									display: true
								},
								plugins: {
									datalabels: {
										formatter: (tooltipItem) => {
											// console.log('tooltioasdf', tooltipItem.index)
											return tooltipItem + '%'
										},
										color: '#fff',

									},
								}
							}}
						/>

					</div >
			}

		</>
	)
}

export default DoughnutChart
