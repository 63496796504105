// import axios from 'axios'
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/sass/_index.sass';
// import dashboard from '../../assets/images/dashboard.png';
import dashboard from '../../assets/images/Group 9311.svg';
import ApiLoader from '../ApiLoader';

import { useHistory } from 'react-router-dom'
import { baseUrl } from '../../config/config';
// import score_profile from '../../assets/images/score-profile.png';
import TeacherHeader from './TeacherHeader';
import Footer from '../Footer';
// import DoughnutChart from '../DoughnutChart';

function TeacherDashboard() {
  const [dashData, setDashData] = useState([]);
  const [loader, setLoader] = useState(true);
  const history = useHistory();

  const submissionPage = () => {
    history.push('/teacher-submission');
  }

  const previousLevel = () => {
    history.push('/teacher-previous-level');
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(baseUrl + 'dashboard', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        setDashData(res.data);
        setLoader(false);
        // console.log('dashboard',res.data);
      })
  }, [])

  return (
    <>
      {loader && <ApiLoader />}
      <TeacherHeader />
      <div className="dashboard-cover">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-title-cover">
                <h1>Dashboard</h1>
                <div style={{ float: 'right', display: 'inherit' }}>
                  <button onClick={previousLevel} className="Prev-average-btn">Previous Average Level</button>
                  <button onClick={submissionPage} className="record-btn">
                    <svg id="Group_9308" data-name="Group 9308" xmlns="http://www.w3.org/2000/svg" width="14.459" height="22.546" viewBox="0 0 14.459 22.546">
                      <path id="Path_9537" data-name="Path 9537" d="M245.745,260.072c0,1.065-.047,2.132.01,3.194a3.764,3.764,0,0,1-4.047,4.008,3.726,3.726,0,0,1-3.568-2.77,4,4,0,0,1-.115-.954q-.014-3.454,0-6.909a3.888,3.888,0,0,1,7.591-1.012,4.3,4.3,0,0,1,.126,1.026C245.755,257.794,245.745,258.933,245.745,260.072Z" transform="translate(-234.653 -252.917)" fill="#fff" />
                      <path id="Path_9538" data-name="Path 9538" d="M199.073,362.768a17.477,17.477,0,0,1-2.223-.789,7,7,0,0,1-4.063-6.459c-.022-.9,0-1.808,0-2.713v-.383h1.808c0,.483-.014.95,0,1.416a19.068,19.068,0,0,0,.148,2.81,5.385,5.385,0,0,0,10.62-.55c.1-1.069.043-2.152.057-3.229,0-.144,0-.289,0-.451h1.742c.017.02.047.038.047.055a33.49,33.49,0,0,1-.067,4A7.071,7.071,0,0,1,201.3,362.6c-.119.024-.238.051-.376.08v3.082h2.915v1.8h-7.68v-1.776h2.909Z" transform="translate(-192.776 -345.022)" fill="#fff" />
                    </svg>
                    Record Audio
                  </button>
                </div>
              </div>
              <div className="dashboard-body">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 paddding-zero">
                      <div className="score-box">
                        <div className="score-profile-img">
                          {/* <img src={score_profile} alt="" /> */}
                          {dashData?.data?.userdata?.profile_picture && dashData?.data?.userdata?.profile_picture_path !== null
                            ? <img src={dashData?.data?.userdata?.profile_picture_path + dashData?.data?.userdata?.profile_picture} alt="" />
                            : <i className="fa fa-user fa-5x" />
                          }
                        </div>
                        <div className="score-info">
                          <div className="score-text">
                            Hi, {dashData?.data?.userdata?.first_name} {dashData?.data?.userdata?.last_name}
                          </div>
                          <div className="score">
                            Current average level <span>{dashData?.data?.avgLevelNo}</span> - {dashData?.data?.avgLevel}
                          </div>
                          <div className="score-text">
                            based on prior submissions
                          </div>
                        </div>
                      </div>

                      <br />
                      {/* <DoughnutChart data={dashData?.data?.chartdata} totalSubm={dashData?.data?.userdata?.totalsubmissions} /> */}
                      <div className="evaluate-cover">
                        <div className="evaluate">
                          <img src={dashboard} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default TeacherDashboard
