import React, { useState } from 'react';
import TeacherHeader from './TeacherHeader';
import Footer from '../Footer';
import { useHistory } from 'react-router-dom';

function TeacherSubmission() {
  const [submitted, setSubmitted] = useState(false);
  // const [validationReq, setValidationReq] = useState(false);
  // const [topicName, setTopicName] = useState('');
  const history = useHistory();
  const [data, setData] = useState({
    lesson_name: '',
    grade: '',
    subject: '',
    expected_student_outcome: '',
    predicted_level: '1',
    learning_objective: '',
    lesson_description: ''
  })

  const recordingPage = () => {
    setSubmitted(true);
    let error = false;
    if (data.lesson_name === '' || data.grade === '' || data.subject === '' || data.expected_student_outcome === '' || data.learning_objective === '' || data.lesson_description === '') {
      error = true
    }
    if (error) {
      return
    }

    console.log('alldata', data);

    history.push({
      pathname: '/teacher-recording',
      state: data
    })
  }
  return (
    <>
      <TeacherHeader />
      <div className="dashboard-cover">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-title-cover">
                <h1>Record Audio</h1>
              </div>
              <div className="dashboard-body bg-white">
                <div className="row">
                  <div className="col-lg-12">

                    <div className="form-group">
                      <label>Lesson Name<span className="red-asterik">*</span></label>
                      <input
                        name="lesson_name"
                        type="text"
                        className="form-control"
                        placeholder="Write lesson name here."
                        // onChange={(e) => setTopicName(e.target.value)}
                        onChange={(e) => setData({ ...data, lesson_name: e.target.value })}
                      />
                      {submitted && data.lesson_name === '' && <p className="text-danger">Topic is required.</p>}
                    </div>


                    <div className="row">
                      <div className="col md-6 responsive">
                        <div className="form-group">
                          <label>Grade<span className="red-asterik">*</span></label>
                          <input
                            name="grade"
                            type="text"
                            className="form-control"
                            placeholder="Write Grade here."
                            onChange={(e) => setData({ ...data, grade: e.target.value })}
                          />
                          {submitted && data.grade === '' && <p className="text-danger">Grade is required.</p>}
                        </div>
                      </div>
                      <div className="col md-6 responsive">
                        <div className="form-group">
                          <label>Subject<span className="red-asterik">*</span></label>
                          <input
                            name="subject"
                            type="text"
                            className="form-control"
                            placeholder="Write Subject here."
                            onChange={(e) => setData({ ...data, subject: e.target.value })}
                          />
                          {submitted && data.subject === '' && <p className="text-danger">Subject is required.</p>}
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col md-6 responsive">
                        <div className="form-group">
                          <label>Expected Student Outcome<span className="red-asterik">*</span></label>
                          <input
                            name="expected_student_outcome"
                            type="text"
                            className="form-control"
                            placeholder="Write Expected Student Outcome here."
                            onChange={(e) => setData({ ...data, expected_student_outcome: e.target.value })}
                          />
                          {submitted && data.expected_student_outcome === '' && <p className="text-danger">Expected Student Outcome is required.</p>}
                        </div>
                      </div>
                      <div className="col md-6 responsive">
                        <div className="form-group">
                          <label>Lesson Predicted Level<span className="red-asterik">*</span></label>
                          <br></br>
                          {/* <input
                            name="predicted_level"
                            type="text"
                            className="form-control"
                            placeholder="Write Lesson Predicted Level here."
                            onChange={(e) => setData({ ...data, predicted_level: e.target.value })}
                          />
                          {submitted && data.predicted_level === '' && <p className="text-danger">Lesson Predicted Level is required.</p>} */}
                          <select 
                            className="form-control"
                            onChange={(e) => setData({ ...data, predicted_level: e.target.value })}
                          >
                            <option selected value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                        
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col md-6 responsive">
                        <div className="form-group">
                          <label>Learning Objective<span className="red-asterik">*</span></label>
                          <textarea
                            rows="4"
                            name="learning_objective"
                            type="text"
                            className="form-control"
                            placeholder="Write Learning Objective here."
                            onChange={(e) => setData({ ...data, learning_objective: e.target.value })}
                          />
                          {submitted && data.learning_objective === '' && <p className="text-danger">Learning Objective is required.</p>}
                        </div>
                      </div>
                      <div className="col md-6 responsive">
                        <div className="form-group">
                          <label>Lesson Description<span className="red-asterik">*</span></label>
                          <textarea
                            rows="4"
                            name="lesson_description"
                            type="text"
                            className="form-control"
                            placeholder="Write Lesson Description here."
                            onChange={(e) => setData({ ...data, lesson_description: e.target.value })}
                          />
                          {submitted && data.lesson_description === '' && <p className="text-danger">Lesson Description is required.</p>}
                        </div>
                      </div>
                    </div>


                    <button className="blue-btn btn mt-2" onClick={recordingPage} >Record Now</button>
                    <div className="conditions">
                      {/* <p><span className="red-asterik">*</span>Supporting files mp3</p> */}
                      <p><span className="red-asterik">*</span>You can upload 1 lesson at a time</p>
                      <p><span className="red-asterik">*</span>Length of a submission should not exceed 1 hour</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TeacherSubmission
