import React from 'react';
import 'font-awesome/css/font-awesome.min.css';

function ApiLoader() {
  return (
    <React.Fragment>
      <div className="loader-icon">
        <i className="fa fa-spinner fa-2x fa-spin" />
      </div>
    </React.Fragment>
  )
}
export default ApiLoader
