import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../../config/config';
import ApiLoader from '../ApiLoader';
import TeacherHeader from './TeacherHeader';
import { Accordion, Card } from 'react-bootstrap';
import arrowDown from '../../assets/images/down-arrow.png';
import arrowUp from '../../assets/images/up-arrow.png';
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from '../Footer';
// import { useSelector, useDispatch } from 'react-redux';
// import { fetchUsers } from '../../redux/action/teacherActions';

function TeacherFaq() {
  // const [selected, setSelected] = useState(null);
  const [datas, setDatas] = useState(null);
  const [selectedAccordian, setSelectedAccordian] = useState([]);
  const [loader, setLoader] = useState(true);

  // const dispatch=useDispatch();
  // const users = useSelector(state => state.teacher)
  // console.log('hellllllllo',users);
  // console.log('asdfa');
  // dispatch(fetchUsers()); 
  // console.log('aasdfaf',store.getState())

  useEffect(() => {
    
    axios.get(baseUrl + 'faq')
      .then((res) => {
        setDatas(res.data.data.data);
        setLoader(false);
        // console.log(res.data.data.data);
      })
  }, [])

  function accordionOnOff(id) {

    var currentVal = [...selectedAccordian]

    if (selectedAccordian.includes(id)) {
      const index = selectedAccordian.indexOf(id);
      if (index > -1) {
        currentVal.splice(index, 1);
      }

    } else {
      currentVal.push(id);
    }
    
    setSelectedAccordian(currentVal);
    // console.log(selectedAccordian);
  }
  // console.log('selected accordian outside', selectedAccordian)
  return (
    <>
      <TeacherHeader />
      <div className="dashboard-cover">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-title-cover">
                <h1>FAQ's</h1>
              </div>
              <div className="dashboard-body bg-white p-3">
                <div className="content-box">
                {loader && <ApiLoader />}
                  <div className="accordion-cover">
                    {
                      datas?.sort((x, y) => x.id - y.id).map((data, index) => {
                        return (
                          <>
                            <Accordion key={index}>
                              <Card onClick={() => accordionOnOff(data.id)} key={data?.id}>
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                                  <div className="accordion-panel" key={index}>
                                    <div className="accordion-header" >
                                      <p key={index}>{data?.question}</p>
                                    </div>
                                    <div className="accordion-button">
                                      <button className="accordion-arrow btn" >
                                        <img src={selectedAccordian.includes(data?.id) ? arrowUp : arrowDown} alt="" />
                                        {/* {console.log('selected Accordian',selectedAccordian, data.id)} */}
                                      </button>
                                    </div>
                                      <Accordion.Collapse eventKey="0" key={index}>
                                        <Card.Body key={index}>
                                          <p key={index} dangerouslySetInnerHTML={{__html:data?.answer}}></p>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                  </div>
                                </Accordion.Toggle>
                              </Card>
                            </Accordion>
                          </>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TeacherFaq
