/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../config/config";
import TeacherHeader from "./TeacherHeader";
import Footer from "../Footer";
import play from "../../assets/images/play.png";
// import pause from '../../assets/images/pause.png';
import stop from "../../assets/images/stop.png";
import record from "../../assets/images/record-btn.png";
import MicRecorder from "mic-recorder-to-mp3";
import { ToastContainer, toast } from "react-toastify";
import S3 from "react-aws-s3";
import ApiLoader from "../ApiLoader";
import { useHistory } from "react-router-dom";

import { Helmet } from "react-helmet";
// import ReactS3Uploader from 'react-s3-uploader';
// import {Lame} from 'node-lame'
// const Lame = require("node-lame").Lame;
// import Recorder from 'react-mp3-recorder'
// import Mp3Lame from './Mp3Lame.js';
{
    /* <script src="./Mp3LameEncoder.js"></script> */
}

function TeacherRecording(props) {
    const [data, setData] = useState();
    const [time, setTime] = useState(0);
    const [timerOn, setTimerOn] = useState(false);
    const [isMount, setIsMount] = useState(true);
    const [disableBtnPlay, setDisableBtnPlay] = useState(false);
    const [disableStopBtn, setDisableStopBtn] = useState(true);
    const [loader, setLoader] = useState(false);
    // const [bufferData, setBufferData] = useState([])
    // const [bufferDataCopy, setBufferDataCopy] = useState([])

    const history = useHistory();
    const config = {
        bucketName: "input-audio-file",
        region: "us-east-2",
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_KEY_ACCESS,
        type: "audio/mp3",
    };
    const ReactS3Client = new S3(config);

    const [recorder, setrecorder] = useState(new MicRecorder({ bitRate: 128 }));

    // useEffect(() => {
    //   const script = document.createElement('script');
    //   script.src = "./Mp3LameEncoder.js";
    //   script.async = true;
    //   document.body.appendChild(script);
    // return () => {
    //     document.body.removeChild(script);
    //   }
    // }, []);

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios
            .get(baseUrl + "dashboard", {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                setData(res.data.data.userdata);
                // console.log('dashboard',res.data.data.userdata);
            });
    }, []);

    useEffect(() => {
        let interval = null;

        if (timerOn) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 1000);
            }, 1000);
        }
        // else if (!timerOn) {
        //   clearInterval(interval);
        // }
        console.log("interval", interval);
        return () => {
            clearInterval(interval);
            console.log("clearinterval");
        };
    }, [timerOn, isMount]);

    let minutes = ("0" + Math.floor((time / 60000) % 60)).slice(-2);
    let seconds = ("0" + Math.floor((time / 1000) % 60)).slice(-2);

    useEffect(() => {
        minutes == 50 && toast.info("10 Minutes Remaining");
        // setTimeout(() => {
        //   minutes>=59 && toast.success('Recording is uploading Successfully')
        // }, 65000)
    }, [minutes]);

    useEffect(() => {
        return () => {
            setIsMount(false);
            console.log("is componenet unmount");
        };
    }, []);

    const playRecording = () => {
        setDisableBtnPlay(true);
        setDisableStopBtn(false);
        setTimerOn(true);

        recorder
            .start()
            .then(() => {
                console.log("recording started");
            })
            .catch((e) => {
                console.error(e);
            });
    };

    // let audioElement = document.querySelector('audio');
    // let encoder = null;
    // let microphone;
    // let isRecording = false;
    // var audioContext;
    // let processor;
    // let configAudio = {
    //   bufferLen: 4096,
    //   numChannels: 2,
    //   mimeType: 'audio/mpeg'
    // };
    // let buffers = [];

    // const playRecording = () => {
    //   setDisableBtnPlay(true);
    //   setDisableStopBtn(false);
    //   setTimerOn(true);

    //   try {
    //     window.URL = window.URL || window.webkitURL;
    //     window.AudioContext = window.AudioContext || window.webkitAudioContext;
    //     navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
    //     console.log("cool audio context established");
    // } catch(e) {
    //     alert("Web Audio API is not supported by this browser.")
    //   }// var recorder = new RecordVoiceAudios();

    //   audioContext = new AudioContext();
    // 			/**
    // 			* Create a ScriptProcessorNode with a bufferSize of
    // 			* 4096 and two input and output channel
    // 			* */
    // 			if (audioContext) {
    //         console.log('audioContext.createScriptProcessor',audioContext?.createScriptProcessor)
    // 				processor = audioContext?.createScriptProcessor(configAudio.bufferLen, configAudio.numChannels, configAudio.numChannels);
    // 			} else if (audioContext?.createScriptProcessor) {
    // 				processor = audioContext?.createScriptProcessor(configAudio.bufferLen, configAudio.numChannels, configAudio.numChannels);
    // 			} else {
    // 				console.log('WebAudio API has no support on this browser.');
    // 			}

    // 			processor?.connect(audioContext?.destination);
    // 			/**
    // 			*  ask permission of the user for use microphone or camera
    // 			* */
    // 			navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    // 			.then(gotStreamMethod)
    // 			.catch(logError);
    //   // recorder.start().then(() => {
    //   //   console.log('recording started');
    //   // }).catch((e) => {
    //   //   console.error(e);
    //   // });
    // }
    // let getBuffers = (event) => {
    //   // debugger
    //   let buffers = []
    //   for (var ch = 0; ch < 2; ++ch)
    //     buffers[ch] = event.inputBuffer.getChannelData(ch);
    //   return buffers;
    // }
    // let logError = (error) => {
    //   // alert(error);
    //   console.log(error);
    // }

    //Encrypt data id_title_created_at
    const name =
        data?.id +
        "_" +
        window?.history?.state?.state?.lesson_name +
        "_" +
        data?.created_at;
    let buff = new Buffer(name);
    let stringToBase64 = buff.toString("base64");
    // console.log('before : ', stringToBase64);
    stringToBase64 = stringToBase64.replace("=", "");
    stringToBase64 = stringToBase64.replace("=", "");
    // console.log('after : ', stringToBase64);

    const formData = new FormData();
    formData.append("filename", stringToBase64);
    let randomNO = Math.floor(Math.random() * 6) + 1;
    formData.append("level", randomNO);
    formData.append("grade", window?.history?.state?.state?.grade);
    formData.append(
        "learning_objective",
        window?.history?.state?.state?.learning_objective
    );
    formData.append(
        "lesson_description",
        window?.history?.state?.state?.lesson_description
    );
    formData.append(
        "expected_student_outcome",
        window?.history?.state?.state?.expected_student_outcome
    );
    formData.append(
        "predicted_level",
        window?.history?.state?.state?.predicted_level
    );
    formData.append("subject", window?.history?.state?.state?.subject);

    // let gotStreamMethod = (stream) => {
    //   console.log('got streean', audioElement)
    //   console.log('processor', processor)
    //   console.log('audio context', audioContext)
    //   audioElement.src = "";
    //   configAudio = {
    //     bufferLen: 4096,
    //     numChannels: 2,
    //     mimeType: 'audio/mpeg'
    //   };
    //   isRecording = true;

    //   microphone = audioContext?.createMediaStreamSource(stream);

    //   microphone?.connect(processor);
    //   encoder = new Mp3Lame(audioContext?.sampleRate, 160);

    //   processor.onaudioprocess = (event) => {
    //     encoder.encode(getBuffers(event));
    //   };
    // }

    // const stopRecording = () => {
    //   console.log('buffer dATa', bufferData)
    //   console.log('buffer data cpy', bufferDataCopy)
    //   setDisableBtnPlay(false);
    //   setDisableStopBtn(true);
    //   setTimerOn(false);
    //   setTime(0);
    //   console.log('stopBtnRecord');
    //   audioContext?.close();
    //   processor?.disconnect();
    //   // encoder?.finish();
    //   // tracks.forEach(track => track.stop());
    //   // audioElement.src = URL.createObjectURL(encoder?.finish());
    //   // console.log('audio src after stop', audioElement.src)

    //   // const file = new File(bufferData?.mp3Buffers, 'music.mp3', {
    //   //   type: 'audio/mpeg',
    //   //   lastModified: Date.now()
    //   // });
    // };

    // const stopRecording = () => {
    //   setDisableBtnPlay(false);
    //   setDisableStopBtn(true);
    //   setTimerOn(false);
    //   setTime(0);
    //   setLoader(true);
    //   recorder.stop().getMp3().then(([buffer, blob]) => {
    //     // console.log(buffer, blob);
    //     const file = new File(buffer, 'music.mp3', {
    //       type: blob.type,
    //       lastModified: Date.now()
    //     });
    //     console.log('File data',file)
    //     ReactS3Client
    //     .uploadFile(file, `${stringToBase64}.mp3`)
    //     .then(data => {
    //       console.log('s3 upload response', data);
    //       setLoader(false);
    //       toast.success('Recording Uploaded Successfully')

    //       axios.post(baseUrl + 'saveTempSubmission', formData)
    //         .then((res) => {
    //           console.log('dataSend',res);
    //           setTimeout(() => {
    //             history.push('/ns/ABRTL-WEB/teacher-report')
    //           }, 5000)
    //         })
    //         .catch((err) => console.log('error in api fetch'));
    //     })
    //     .catch(err => console.error(err))
    //   })
    // }
    const stopRecording = () => {
        setDisableBtnPlay(false);
        setDisableStopBtn(true);
        setTimerOn(false);
        setTime(0);
        setLoader(true);
        recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                // console.log(buffer, blob);
                const file = new File(buffer, "music.mp3", {
                    type: blob.type,
                    lastModified: Date.now(),
                });
                console.log("File data", file);
                ReactS3Client.uploadFile(file, `${stringToBase64}.mp3`)
                    .then((data) => {
                        console.log("s3 upload response", data);
                        setLoader(false);
                        toast.success("Recording Uploaded Successfully");

                        axios
                            .post(baseUrl + "saveTempSubmission", formData)
                            .then((res) => {
                                console.log("dataSend", res);
                                setTimeout(() => {
                                    history.push("/teacher-report");
                                }, 5000);
                            })
                            .catch((err) => console.log("error in api fetch"));
                    })
                    .catch((err) => console.error(err));
            });
    };

    return (
        <>
            {loader && <ApiLoader />}
            <TeacherHeader />
            {/* <ReactS3Uploader
      signingUrl="/s3/sign"
      signingUrlMethod="POST"
      accept="audio/*"
      s3path="/uploads/"
      // onSignedUrl={onSignedUrl}
      // onProgress={this.onUploadProgress}test
      // onError={this.onUploadError}
      // onFinish={this.onUploadFinish}
      uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}  // this is the default
      scrubFilename={() => `${stringToBase64}.mp3`}
      // inputRef={cmp => this.uploadInput = cmp}
      autoUpload={true}
    /> */}

            <div className="dashboard-cover">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="dashboard-title-cover">
                                <h1>Recording Audio</h1>
                            </div>
                            <div className="dashboard-body bg-white pb-0">
                                <div className="record-cover">
                                    <div className="record-body">
                                        <div className="record-img">
                                            <a>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="167"
                                                    height="167"
                                                    viewBox="0 0 167 167"
                                                >
                                                    <g
                                                        id="Group_9332"
                                                        data-name="Group 9332"
                                                        transform="translate(-5473 292)"
                                                    >
                                                        <g
                                                            id="Group_9331"
                                                            data-name="Group 9331"
                                                            transform="translate(4756 -551)"
                                                        >
                                                            <g
                                                                id="Group_9329"
                                                                data-name="Group 9329"
                                                            >
                                                                <circle
                                                                    id="Ellipse_14"
                                                                    data-name="Ellipse 14"
                                                                    cx="83.5"
                                                                    cy="83.5"
                                                                    r="83.5"
                                                                    transform="translate(717 259)"
                                                                    fill="#e4f4ff"
                                                                />
                                                            </g>
                                                        </g>
                                                        <g
                                                            id="Group_9308"
                                                            data-name="Group 9308"
                                                            transform="translate(5528.941 -250.867)"
                                                        >
                                                            <path
                                                                id="Path_9537"
                                                                data-name="Path 9537"
                                                                d="M267.06,279.808c0,4-.176,8.013.037,12,.454,8.514-6.111,15.459-15.209,15.062-6.431-.281-11.852-4.244-13.408-10.411a15.037,15.037,0,0,1-.431-3.585q-.054-12.983-.014-25.965a13.64,13.64,0,0,1,14.047-14c7.458-.015,12.778,3.694,14.482,10.192a16.174,16.174,0,0,1,.474,3.857C267.1,271.246,267.06,275.527,267.06,279.808Z"
                                                                transform="translate(-225.375 -252.917)"
                                                                fill="#008deb"
                                                            />
                                                            <path
                                                                id="Path_9538"
                                                                data-name="Path 9538"
                                                                d="M216.44,391.307c-2.881-1-5.787-1.652-8.354-2.965-9.844-5.034-14.994-13.169-15.269-24.273-.084-3.4-.013-6.8-.014-10.195v-1.44H199.6c0,1.813-.052,3.57.011,5.322.126,3.527-.088,7.126.555,10.562a20.112,20.112,0,0,0,20.414,16c10.364-.579,18.589-8.072,19.5-18.069.366-4.019.16-8.089.213-12.137.007-.542,0-1.085,0-1.694h6.546c.065.074.176.141.176.208-.046,5.021.393,10.106-.251,15.049-1.563,11.983-10.07,20.627-21.932,23.015-.449.09-.9.19-1.412.3v11.582h10.955v6.774H205.508v-6.675H216.44Z"
                                                                transform="translate(-192.776 -324.616)"
                                                                fill="#008deb"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>
                                        </div>
                                        <div className="record-time">
                                            {/* <span className="time-digit">02</span><span className="time-unit">M</span>&nbsp;&nbsp;&nbsp; */}
                                            <span className="time-digit">
                                                {minutes >= 59 && seconds == 59
                                                    ? stopRecording()
                                                    : minutes}
                                            </span>
                                            <span className="time-unit">M</span>
                                            &nbsp;&nbsp;&nbsp;
                                            {/* <span className="time-digit"></span>00<span className="time-unit">S</span> */}
                                            <span className="time-digit">
                                                {seconds}
                                            </span>
                                            <span className="time-unit">S</span>
                                        </div>
                                        {/* <div className="record-btn">
                    <a>
                      <img src={record} alt="" />
                    </a>
                  </div> */}
                                        <div className="record-title">
                                            {
                                                window?.history?.state?.state
                                                    ?.lesson_name
                                            }
                                            {/* Audio Name */}
                                        </div>
                                        <div className="record-controls">
                                            <div className="control-box">
                                                {/* <Recorder
                        startRecordingTimer={_onStartRecordingTimer}
                        onRecordingComplete={_onRecordingComplete}
                        onRecordingError={_onRecordingError}
                      /> */}
                                                <audio
                                                    style={{ display: "none" }}
                                                    controls
                                                    type="audio/mpeg"
                                                ></audio>
                                                <span>
                                                    <button
                                                        disabled={
                                                            disableBtnPlay
                                                        }
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                        }}
                                                        onClick={playRecording}
                                                        title="Start Recording"
                                                    >
                                                        {/* {disableStopBtn && <img src={play} alt="" />} */}
                                                        {disableStopBtn && (
                                                            <i
                                                                className="fa fa-play fa-2x"
                                                                style={{
                                                                    color: "#d83538",
                                                                }}
                                                            />
                                                        )}
                                                    </button>
                                                </span>

                                                <span>
                                                    <button
                                                        disabled={
                                                            disableStopBtn
                                                        }
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                        }}
                                                        onClick={stopRecording.bind()}
                                                        title="Stop and Upload"
                                                    >
                                                        {/* { disableBtnPlay && <img src={stop} alt="" />} */}
                                                        {disableBtnPlay && (
                                                            <i
                                                                className="fa fa-stop fa-2x"
                                                                style={{
                                                                    color: "#d83538",
                                                                }}
                                                            />
                                                        )}
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

export default TeacherRecording;

// 4 try
// import React, {useState, useEffect} from 'react'
// import Recorder from 'react-mp3-recorder'
// import S3 from 'react-aws-s3';
// import axios from 'axios';
// import {baseUrl} from '../../config/config';
// import { ToastContainer, toast } from 'react-toastify';
// import {useHistory} from 'react-router-dom';

// function TeacherRecording(props) {
//   const [data, setData] = useState();
//   const history = useHistory();

//   const config = {
//     bucketName: 'input-audio-file',
//     region: 'us-east-2',
//     accessKeyId: 'AKIAUZXHYB7DMJDXTVNW',
//     secretAccessKey: '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',
//     type: 'audio/mp3'
//   }

//   useEffect(() => {
//         const token = localStorage.getItem('token');
//         axios.get(baseUrl + 'dashboard', { headers: {"Authorization" : `Bearer ${token}`} })
//           .then((res) => {
//             setData(res.data.data.userdata);
//             // console.log('dashboard',res.data.data.userdata);
//         })
//       }, [])

//   const ReactS3Client = new S3(config);

//   //Encrypt data id_title_created_at
//   const name = data?.id+'_'+window?.history?.state?.state?.lesson_name+'_'+data?.created_at;
//   let buff = new Buffer(name);
//   let stringToBase64 = buff.toString('base64');
//   // console.log('before : ', stringToBase64);
//   stringToBase64 = stringToBase64.replace("=", "");
//   stringToBase64 = stringToBase64.replace("=", "");
//   // console.log('after : ', stringToBase64);

//   const formData = new FormData();
//   formData.append('filename', stringToBase64);
//   let randomNO = Math.floor(Math.random() * 6) + 1;
//   formData.append('level', randomNO);
//   formData.append('grade',window?.history?.state?.state?.grade)
//   formData.append('learning_objective',window?.history?.state?.state?.learning_objective)
//   formData.append('lesson_description',window?.history?.state?.state?.lesson_description)
//   formData.append('expected_student_outcome',window?.history?.state?.state?.expected_student_outcome)
//   formData.append('predicted_level',window?.history?.state?.state?.predicted_level)
//   formData.append('subject',window?.history?.state?.state?.subject)

//   const _onRecordingComplete = (blob) => {
//     console.log('recording', blob)

//     ReactS3Client
//     .uploadFile(blob, `${stringToBase64}.mp3`)
//     .then(data => {
//       console.log('s3 upload response', data);
//       // setLoader(false);
//       toast.success('Recording Uploaded Successfully')

//       axios.post(baseUrl + 'saveTempSubmission', formData)
//         .then((res) => {
//           console.log('dataSend',res);
//           setTimeout(() => {
//             history.push('/ns/ABRTL-WEB/teacher-report')
//           }, 5000)
//         })
//         .catch((err) => console.log('error in api fetch'));
//     })
//     .catch(err => console.error(err))

//   }

//   const _onRecordingError = (err) => {
//     console.log('recording error', err)
//   }

//   return (
//         <>
//           <h1>Recording</h1>
//           {/* <button id="recordButton" onClick={recordButton}>Button</button> */}

//           <Recorder
//             onRecordingComplete={_onRecordingComplete}
//             onRecordingError={_onRecordingError}
//           />

//         </>
//       )
// }

// export default TeacherRecording

// 3 try
// import React from 'react'
// // import { record } from "./vmsg.js";
// // import xyz from "../../assets/Js/vmsg.wasm"
// // import Recorder from './RecordComponent/index'
// import Recorder from 'react-mp3-recorder'

// function TeacherRecording(props) {

//   const _onRecordingComplete = (blob) => {
//     console.log('recording', blob)
//   }

//   const _onRecordingError = (err) => {
//     console.log('recording error', err)
//   }

//   return (
//         <>
//           <h1>Recording</h1>
//           {/* <button id="recordButton" onClick={recordButton}>Button</button> */}

//           <Recorder
//             onRecordingComplete={_onRecordingComplete}
//             onRecordingError={_onRecordingError}
//           />

//         </>
//       )
// }

// export default TeacherRecording

// 2 try core js

// import React, {useState, useEffect} from 'react'
// import Recorder from 'react-mp3-recorder'
// import S3 from 'react-aws-s3';
// import axios from 'axios';
// import {baseUrl} from '../../config/config';
// import { ToastContainer, toast } from 'react-toastify';
// import {useHistory} from 'react-router-dom';

// var mediaRecorder = null
// var audioChunks = null

// function TeacherRecording(props) {

//   const [data, setData] = useState();
//   const history = useHistory();

//   const config = {
//     bucketName: 'input-audio-file',
//     region: 'us-east-2',
//     accessKeyId: 'AKIAUZXHYB7DMJDXTVNW',
//     secretAccessKey: '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',
//     type: 'audio/mp3'
//   }

//   useEffect(() => {
//         const token = localStorage.getItem('token');
//         axios.get(baseUrl + 'dashboard', { headers: {"Authorization" : `Bearer ${token}`} })
//           .then((res) => {
//             setData(res.data.data.userdata);
//             // console.log('dashboard',res.data.data.userdata);
//         })
//       }, [])

//   const ReactS3Client = new S3(config);

// //   //Encrypt data id_title_created_at
//   const name = data?.id+'_'+window?.history?.state?.state?.lesson_name+'_'+data?.created_at;
//   let buff = new Buffer(name);
//   let stringToBase64 = buff.toString('base64');
//   // console.log('before : ', stringToBase64);
//   stringToBase64 = stringToBase64.replace("=", "");
//   stringToBase64 = stringToBase64.replace("=", "");
//   // console.log('after : ', stringToBase64);

//   const formData = new FormData();
//   formData.append('filename', stringToBase64);
//   let randomNO = Math.floor(Math.random() * 6) + 1;
//   formData.append('level', randomNO);
//   formData.append('grade',window?.history?.state?.state?.grade)
//   formData.append('learning_objective',window?.history?.state?.state?.learning_objective)
//   formData.append('lesson_description',window?.history?.state?.state?.lesson_description)
//   formData.append('expected_student_outcome',window?.history?.state?.state?.expected_student_outcome)
//   formData.append('predicted_level',window?.history?.state?.state?.predicted_level)
//   formData.append('subject',window?.history?.state?.state?.subject)

//   const start = () => {
//     navigator.mediaDevices.getUserMedia({ audio: true })
//     .then(stream => {
//       mediaRecorder = new MediaRecorder(stream)
//       // setMediaRecorder( newMediaRecorder );
//       mediaRecorder.start();

//       audioChunks = [];
//       mediaRecorder.addEventListener("dataavailable", event => {
//         audioChunks.push(event.data);
//         // setAudioChunks(newAudioChunks);
//       });

//     });
//   }

//   const stop = () => {

//     mediaRecorder.addEventListener("stop", () => {
//       const audioBlob = new Blob(audioChunks,{type:'audio/mpeg-3'});
//       console.log(audioBlob)

//       ReactS3Client
//     .uploadFile(audioBlob, `${stringToBase64}.mp3`)
//     .then(data => {
//       console.log('s3 upload response', data);
//       // setLoader(false);
//       toast.success('Recording Uploaded Successfully')

//       axios.post(baseUrl + 'saveTempSubmission', formData)
//         .then((res) => {
//           console.log('dataSend',res);
//           setTimeout(() => {
//             history.push('/ns/ABRTL-WEB/teacher-report')
//           }, 5000)
//         })
//         .catch((err) => console.log('error in api fetch'));
//     })
//     .catch(err => console.error(err))

//     });

//     mediaRecorder.stop();
//   }

//   return (
//     <>
//     <button onClick={start}>Start</button>
//     <button onClick={stop}>Stop</button>
//     </>
//   )
// }

// export default TeacherRecording

// 1 try

// import React, { useEffect } from 'react'

// function TeacherRecording(props) {
//   let rec = null;
//   let audioChunks = [];
//   let recordedAudio = {};

//   useEffect(() => {
//     alert('hello')
//     navigator.mediaDevices.getUserMedia({audio:true})
//       .then(stream => {handlerFunction(stream)})
//             function handlerFunction(stream) {
//             rec = new MediaRecorder(stream);
//             rec.ondataavailable = e => {
//               audioChunks.push(e.data);
//               if (rec.state == "inactive"){
//                 let blob = new Blob(audioChunks,{type:'audio/mp3'});

//                 let file = new File(audioChunks, 'music.mp3', {
//                   type: blob.type,
//                   lastModified: Date.now()
//                 });

//                 recordedAudio.src = URL.createObjectURL(blob);
//                 recordedAudio.controls=true;
//                 recordedAudio.autoplay=true;
//                 sendData(blob,file)
//               }
//             }
//           }

//   }, [])

//   function sendData(blob, file) {
//     console.log(blob)
//     console.log(file);
//   }

//   const record = e => {
//     console.log('I was clicked to start recording')
//     // record.disabled = true;
//     // record.style.backgroundColor = "blue"
//     stopRecord.disabled=false;
//     audioChunks = [];
//     rec.start();
//   }
//   const stopRecord = e => {
//     console.log("I was clicked to stop recording")
//     // record.disabled = false;
//     // stop.disabled=true;
//     // record.style.backgroundColor = "red"
//     rec.stop();

//   }

//   return (
//     <>
//       <h2>Recording</h2>
//       <button onClick={record}>Start</button>
//       <button onClick={stopRecord}>Stop</button>

//     </>
//   )
// }

// export default TeacherRecording
