import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';
import {PrivateRoute} from './PrivateRoute';
import Home from './components/Home';
import Login from './components/Login';
import ForgotPassword from './components/Login/ForgotPassword';
import ResetPassword from './components/Login/ResetPassword';
import TeacherDashboard from './components/Teacher/TeacherDashboard';
import TeacherReport from './components/Teacher/TeacherReport';
import TeacherSubmission from './components/Teacher/TeacherSubmission';
import TeacherRecording from './components/Teacher/TeacherRecording';
import TeacherViewProfile from './components/Teacher/TeacherViewProfile';
// import TeacherResource from './components/Teacher/TeacherResource';
// import TeacherAboutUs from './components/Teacher/TeacherAboutUs';
import TeacherTermsCondition from './components/Teacher/TeacherTermsCondition';
// import TeacherContactUs from './components/Teacher/TeacherContactUs';
import TeacherFaq from './components/Teacher/TeacherFaq';
import EditProfile from './components/Teacher/EditProfile';
import PageNotFound from './components/PageNotFound';
import TeacherSubMenuResources from './components/Teacher/TeacherSubMenuResources';
import PreviousLevel from './components/Teacher/PreviousLevel';
// import basePath from 'Utils/constants.js';

function App() {

  return (
    <Provider store={store}>
      <>
        {/* {console.log(basePath)}; */}
        {/* {console.log('helllo',window.location.hostname)} */}
        <BrowserRouter >
          <Switch>
            
            
            <Route exact path="/" component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password/:id" component={ResetPassword} />

            <PrivateRoute path='/teacher-dashboard' component={TeacherDashboard}  />
            <PrivateRoute path='/teacher-report' component={TeacherReport} />
            <PrivateRoute path='/teacher-submission' component={TeacherSubmission} />
            <PrivateRoute path='/teacher-recording' component={TeacherRecording} />
            <PrivateRoute path='/teacher-profile' component={TeacherViewProfile} />
            
            {/* <PrivateRoute path='/ns/ABRTL-WEB/teacher-resource' component={TeacherResource} /> */}
            {/* <PrivateRoute path='/ns/ABRTL-WEB/teacher-aboutus' component={TeacherAboutUs} /> */}
            <Route path='/teacher-termsCondition' component={TeacherTermsCondition} />
            
            {/* <PrivateRoute path='/ns/ABRTL-WEB/teacher-contactus' component={TeacherContactUs} /> */}
            
            <PrivateRoute path='/page/:resource' component={TeacherSubMenuResources} />

            <PrivateRoute path='/teacher-faq' component={TeacherFaq} />
            <PrivateRoute path='/teacher-EditProfile' component={EditProfile} />
            <PrivateRoute path='/teacher-previous-level' component={PreviousLevel} />
            <Route component={PageNotFound} />



            {/* <Route path='/ns/ABRTL-WEB/teacher-dashboard' component={TeacherDashboard} />
            <Route path='/ns/ABRTL-WEB/teacher-report' component={TeacherReport} />
            <Route path='/ns/ABRTL-WEB/teacher-submission' component={TeacherSubmission} />
            <Route path='/ns/ABRTL-WEB/teacher-recording' component={TeacherRecording} />
            <Route path='/ns/ABRTL-WEB/teacher-profile' component={TeacherViewProfile} />
            <Route path='/ns/ABRTL-WEB/teacher-trainingMaterial' component={TeacherTrainingMaterial} />
            <Route path='/ns/ABRTL-WEB/teacher-aboutus' component={TeacherAboutUs} />
            <Route path='/ns/ABRTL-WEB/teacher-termsCondition' component={TeacherTermsCondition} />
            <Route path='/ns/ABRTL-WEB/teacher-contactus' component={TeacherContactUs} />
            <Route path='/ns/ABRTL-WEB/teacher-faq' component={TeacherFaq} />
            <Route path='/ns/ABRTL-WEB/teacher-EditProfile' component={EditProfile} /> */}

          </Switch>
        </BrowserRouter>
      </>
    </Provider>
  );
}

export default App;
