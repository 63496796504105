/* eslint-disable */
import {
	FETCH_USER_REQUEST,
	FETCH_USER_SUCCESS,
	FETCH_USER_FAILURE
} from '../actionTypes/loginActionType';

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_USER_REQUEST:
			return {
				...state,
				loading: true
			}
		case FETCH_USER_SUCCESS:
			return {
				user: action.payload,
				loading: false,
			}
		case FETCH_USER_FAILURE:
			return {
				loading: false,
				users: [],
				error: action.payload
			}
		default:
			return state
	}
}