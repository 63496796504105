import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import {baseRoutes, clientRoutes} from "base-routes";

export const PrivateRoute = ({ component: Component, ...rest }) => {
    let urlType = window.location.pathname ? true : false;
    return (
        < Route {...rest} render={props => (
            localStorage.getItem('token')
                ? <Component {...props} />
                :
                (urlType)
                    ?
                    // <Redirect to='/ns/ABRTL-WEB/' />
                    <Redirect to='/login' />
                    :
                    // <Redirect to='/ns/ABRTL-WEB/teacher-dashboard' />
                    null
        )} />
    )
}
