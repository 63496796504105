// import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
// import error from '../assets/images/404@2x.png'

// export default class PageNotFound extends Component {
//   render() {
//     return (
//       <section className="main_section wrong_part not_found">
//         <div className="container">
//           <div className="row homie">
//             <div className="col-md-12">
//               <div className="left_part">
//                 {/* <h1 className="some">404 | Page Not Found !</h1> */}
//                 {/* <h1 className="some exclamation">!</h1> */}
//                 <div className="bottom_buttons error_button">
//                     <img src={error} alt="" />
//                 <Link to={'/ns/ABRTL-WEB/teacher-dashboard'}>GO TO HOME</Link>
//                     {/* <a href="/ab/gps/web-stage/" className="yellow_btn">Go to Home</a> */}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     )
//   }
// }

import React from 'react';
import {Link} from 'react-router-dom';
import error from '../assets/images/404@2x.png';

function PageNotFound() {
  return (
    <section className="main_section wrong_part not_found">
      <div className="container">
        <div className="row homie">
          <div className="col-md-12">
            <div className="left_part">
              {/* <h1 className="some">404 | Page Not Found !</h1> */}
              {/* <h1 className="some exclamation">!</h1> */}
              <div className="bottom_buttons error_button">
                  <img src={error} alt="" />
              <Link to={'/teacher-dashboard'}>GO TO HOME</Link>
                  {/* <a href="/ab/gps/web-stage/" className="yellow_btn">Go to Home</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PageNotFound
