import React, { useState } from 'react'
import {baseUrl} from '../../config/config';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import '../../assets/sass/_login.sass';
import logo from '../../assets/images/logo.png';
import computer from '../../assets/images/computer-login.svg';

function ForgotPassword() {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  // const [success, setSuccess] = useState(false);
  const [invalidCredential, setInvalidCredential] = useState(false);
  const [data, setData] = useState({
    email: '',
    isEmailValid: true,
    submitted: false

  })

  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setData((data) => ({ ...data, submitted: true }))
    let error = false;
    if (data.email === "") {
      error = true;
    }
    if (data.email !== "") {
      if (!new RegExp(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g).test(data.email)) {
        setData((data) => ({ ...data, isEmailValid: false }))
        // setData({...data, isEmailValid: false});
        error = true;
      }
    }
    if (error) {
      return;
    }

    setLoader(true);
    const formData = new FormData();
    formData.append('email', data?.email);

    axios.post(baseUrl+'forgot-password', formData)
    .then(res => {
      if(!res?.data?.error) {
        // setSuccess(true);
        toast.success('Sent you an email to reset your login password. Redirecting to Login');
        // setLoader(true);
        setInvalidCredential(false);
        setLoader(true);
        setTimeout(() => {
          // history.push('/ns/ABRTL-WEB/')
          history.push('/login') 
        }, 5000);
      } else {
        setInvalidCredential(true);
        // setSuccess(false);
      }
      // console.log(res.data)
      setData((data) => ({...data, email: ""}));
      setData((data) => ({...data, submitted: false}));
    })

  }

  return (
    <>
    <div className="register-wrapper">
      <div className="register-outer">
        <div className="register-box">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="logo-panel">
                  <img src={logo} alt="test-img" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 order-lg-1 order-2">
                <form onSubmit={handleSubmit}>
                  <div className="form-box">
                    <div className="register-title">
                      <h1>Forgot Password</h1>
                    </div>
                  
                    {invalidCredential && <div className="alert alert-danger"> <strong>Credential Does Not Match!</strong></div> }
                    {/* {success &&  <div className="alert alert-success"> <strong>We've sent you an email to reset your login password.</strong></div> } */}
                    <div className="register-form">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          value={data.email}
                          name="email"
                          className="form-control"
                          placeholder="Email ID"
                          onChange={e => setData({ ...data, email: e.target.value })}
                        />
                        {data.submitted && data.email === "" && <p className="text-danger">Email is required.</p>}
                        {data.submitted && !data.isEmailValid && data.email !== "" && <p className="text-danger">Please enter a valid email address.</p>}
                      </div>
                      <div className="btn-cover">
                        <button type="submit" className="submit-btn btn">
                          {loader ? <i className="fa fa-spinner fa-spin" /> :  'Send' }
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <div className="right-panel-img">
                  <img src={computer} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

export default ForgotPassword
