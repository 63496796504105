// /* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/sass/_index.sass';
// import sort from '../../assets/images/sort.png';
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import { baseUrl } from '../../config/config';
import ApiLoader from '../ApiLoader';
import ReactPaginate from 'react-paginate';
import { CSVLink } from "react-csv";
// import play from '../../assets/images/play.png';
import Moment from 'moment';
// import {DateRangePicker} from 'rsuite';
// import 'rsuite/dist/styles/rsuite-default.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import TeacherHeader from './TeacherHeader';
import Footer from '../Footer';
import DoughnutChart from '../DoughnutChart';
import {Modal} from 'react-bootstrap';
// import ModalData from '../ModalData';

function TeacherReport() {
  const [profileData, setProfileData] = useState([]);
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [column, setColumn] = useState('');
  const [order, setOrder] = useState('');
  const [listModal, setListModal] = useState({});
  const [donughtData, setdonughtData] = useState('');
  
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = (list) => {
  //   setShow(true);
  //   setListModal(list);
  // }

  const [modalState, setModalState] = useState( "modal-one" | "modal-two" | "close" )

  const handleShowModalOne = (list) => {
    setModalState("modal-one");
    setListModal(list);
  }

  const previousLevel = () => {
    history.push('/teacher-previous-level');
  }
  
  const handleClose = () => {
    setModalState("close")
  }

  const csvRef = useRef(null);
  const history = useHistory();

  const submissionPage = () => {
    history.push('/teacher-submission');
  }

  // dashboard api call
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(baseUrl + 'dashboard', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        setLoader(false);
        setProfileData(res.data.data);
        // console.log('data',res.data.data);
      })
    // setSerialNo(() => serialNo+1);  
  }, [])

  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  const startDateFun = () => {
    let year = state[0]?.startDate?.getFullYear();
    let month = state[0]?.startDate?.getMonth() + 1;
    let day = state[0]?.startDate?.getDate();

    if (month?.length < 2) {
      month = '0' + month;
    }

    if (day?.length < 2) {
      day = '0' + day;
    }

    // alert([year, month, day].join('-'))

    return [year, month, day].join('-');
  }
  const endDateFun = () => {
    let year = state[0]?.endDate?.getFullYear();
    let month = state[0]?.endDate?.getMonth() + 1;
    let day = state[0].endDate?.getDate();

    if (month?.length < 2) {
      month = '0' + month;
    }

    if (day?.length < 2) {
      day = '0' + day;
    }

    // alert([year, month, day].join('-'))

    return [year, month, day].join('-');
  }

  // list of recording and pagination data
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(baseUrl + `report?page=${pageNumber}&from=${startDateFun()}&to=${endDateFun()}&sortColoum=${column}&sortOrder=${order}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        // console.log('data',res.data.data.data);
        setData(res.data.data);
      })

  }, [pageNumber, state, column, order])


  const pageCount = Math.ceil(data?.total / data?.per_page);
  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
    // console.log('pageNumber',pageNumber)
    // console.log('selected',selected+1)
  }

  const headers = [
    // {label: 'Id', key:'id'},
    { label: 'Submission Name', key: 'title' },
    { label: 'AudioFile', key: 'audiofile' },
    { label: 'Uploaded ON', key: 'updated_at' },
    { label: 'Level', key: 'level' },
    { label: 'Level Name', key: 'level_name' }
  ];
  const csvReport = {
    filename: 'Report.csv',
    headers: headers,
    data: allData
  };
  // console.log('allldata', allData)
  const downloadCSV = () => {
    const token = localStorage.getItem('token');
    axios.get(baseUrl + 'report?type=full', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        // console.log('allll type data',res.data.data);
        setAllData(res.data.data);

        setTimeout(() => {
          csvRef.current.link.click()
        }, 500);
      })
  }

  const donughtDynamicValue = (id) => {
    setModalState("modal-two")
    setLoader(true)
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        // "Content-Type": "multipart/form-data",
        // "Accept": "application/json",
        'Authorization': `Bearer ${token}`
      }
    }
    const formData = new FormData()
    formData.append('submission_id', id);
    // const submission_id=id
    // console.log(submission_id);
    axios.post(baseUrl + 'level-report', formData , config)
    .then(res => {
      // console.log('resdata', res?.data?.data?.chartdata);
      setdonughtData(res?.data)
      setLoader(false)
    } )
    .catch(err => console.log('donught api error'))
  }
  
  return (
    <>
      {loader && <ApiLoader />}
      <TeacherHeader />
      <div className="dashboard-cover">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-title-cover">
                <h1>Audio Reports</h1>
                <div style={{ float: 'right', display: 'inherit' }}>
                  <button onClick={previousLevel} className="Prev-average-btn">Previous Average Level</button>
                  <button onClick={submissionPage} className="record-btn">
                    <svg id="Group_9308" data-name="Group 9308" xmlns="http://www.w3.org/2000/svg" width="14.459" height="22.546" viewBox="0 0 14.459 22.546">
                      <path id="Path_9537" data-name="Path 9537" d="M245.745,260.072c0,1.065-.047,2.132.01,3.194a3.764,3.764,0,0,1-4.047,4.008,3.726,3.726,0,0,1-3.568-2.77,4,4,0,0,1-.115-.954q-.014-3.454,0-6.909a3.888,3.888,0,0,1,7.591-1.012,4.3,4.3,0,0,1,.126,1.026C245.755,257.794,245.745,258.933,245.745,260.072Z" transform="translate(-234.653 -252.917)" fill="#fff" />
                      <path id="Path_9538" data-name="Path 9538" d="M199.073,362.768a17.477,17.477,0,0,1-2.223-.789,7,7,0,0,1-4.063-6.459c-.022-.9,0-1.808,0-2.713v-.383h1.808c0,.483-.014.95,0,1.416a19.068,19.068,0,0,0,.148,2.81,5.385,5.385,0,0,0,10.62-.55c.1-1.069.043-2.152.057-3.229,0-.144,0-.289,0-.451h1.742c.017.02.047.038.047.055a33.49,33.49,0,0,1-.067,4A7.071,7.071,0,0,1,201.3,362.6c-.119.024-.238.051-.376.08v3.082h2.915v1.8h-7.68v-1.776h2.909Z" transform="translate(-192.776 -345.022)" fill="#fff" />
                    </svg>
                    Record Audio
                  </button>
                </div>                
              </div>
              <div className="dashboard-body">
                <div className="report-table ">
                  <div className="table-header">
                    Overall Report
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Teacher Name</th>
                          <th scope="col">Grade</th>
                          <th scope="col">School Name</th>
                          <th scope="col">District</th>
                          <th scope="col">Total Audio</th>
                          <th scope="col">Avg. Level</th>
                          <th scope="col">Level Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{profileData?.userdata?.first_name} {profileData?.userdata?.last_name}</td>
                          <td>{profileData?.userdata?.grade}</td>
                          <td>{profileData?.userdata?.school_name}</td>
                          <td>{profileData?.userdata?.district_name}</td>
                          <td>{profileData?.userdata?.totalsubmissions}</td>
                          <td>{profileData?.avgLevelNo}</td>
                          <td>{profileData?.avgLevel}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                
                {/* <div className="doughnut"> */}
                {/* <DoughnutChart data={profileData?.chartdata} totalSubm={profileData?.userdata?.totalsubmissions}/> */}
                {/* {donughtData ? <DoughnutChart data={donughtData} /> : <p className="donughtError">No Keyword Found For Doughnut Chart</p> } */}
                {/* </div> */}
                
                <div className="report-table-two">
                  <div className="table-header-cover">
                    <div className="table-header">
                      Prior Lesson Submissions
                    </div>
                    <div className="filter report-date-box">

                      <DateRange
                        editableDateInputs={true}
                        onChange={item => {
                          setState([item.selection])
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                        showSelectionPreview={true}
                        startDatePlaceholder={'Start Date'}
                        endDatePlaceholder={'End Date'}
                        maxDate={new Date()}
                        className={"report-table-two table-responsive"}
                      />

                      <div className="submit-btn">
                        <button className="btn" onClick={downloadCSV}>Export Report</button>
                        <CSVLink
                          style={{ display: 'none' }}
                          {...csvReport}
                          ref={csvRef}
                        >
                          Export to CSV
                        </CSVLink>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table testing">
                      <thead>
                        <tr>
                          <th scope="col">S.No.</th>
                          <th scope="col" className="sort-header">Submission Name
                            <button className="sort-icon">
                              <i
                                onClick={() => {
                                  setColumn('title')
                                  setOrder('DESC')
                                }}
                                className="fa fa-caret-up" aria-hidden="true">
                              </i>
                              <i
                                onClick={() => {
                                  setColumn('title')
                                  setOrder('ASC')
                                }}
                                className="fa fa-caret-down" aria-hidden="true">
                              </i>
                            </button>
                          </th>
                          <th scope="col" className="sort-header">Uploaded On
                            <button className="sort-icon">
                              <i
                                onClick={() => {
                                  setColumn('created_at')
                                  setOrder('DESC')
                                }}
                                className="fa fa-caret-up" aria-hidden="true">
                              </i>
                              <i
                                onClick={() => {
                                  setColumn('created_at')
                                  setOrder('ASC')
                                }}
                                className="fa fa-caret-down" aria-hidden="true">
                              </i>
                            </button>
                          </th>
                          <th scope="col" className="sort-header">Level
                            {/* <button className="sort-icon">
                              <i
                                onClick={() => {
                                  setColumn('level')
                                  setOrder('DESC')
                                }}
                                className="fa fa-caret-up" aria-hidden="true">
                              </i>
                              <i 
                                onClick={() => {
                                  setColumn('level')
                                  setOrder('ASC')
                                }}
                                className="fa fa-caret-down" aria-hidden="true">
                              </i>
                            </button> */}
                          </th>
                          <th scope="col" className="sort-header">Level Name
                          {/* <button className="sort-icon">
                              <i
                                onClick={() => {
                                  setColumn('level_name')
                                  setOrder('DESC')
                                }}
                                className="fa fa-caret-up" aria-hidden="true">
                              </i>
                              <i 
                                onClick={() => {
                                  setColumn('level_name')
                                  setOrder('ASC')
                                }}
                                className="fa fa-caret-down" aria-hidden="true">
                              </i>
                            </button> */}
                          </th>
                          <th style={{textAlign: 'center'}} scope="col">Play</th>
                          <th style={{textAlign: 'center'}} scope="col">More Details</th>
                          {/* <th scope="col">BOW File</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data?.data?.length !== 0
                            ?
                            data?.data?.map((list, index) => {
                              return (
                                <tr key={index}>
                                  <td>{((data?.current_page - 1) * 10) + (index + 1)}</td>
                                  <td>{list.title}</td>
                                  <td>{Moment(list.updated_at).format('MM-DD-YYYY')}</td>
                                  <td>{list.status === 0 ? "Pending" : list.status === 2 ? "Failed" : <p onClick={() => donughtDynamicValue(list.id)} className="levelReport">{list.level}</p>}</td>
                                  <td>{list.status === 0 ? "Pending" : list.status === 2 ? "N/A" : list.level_name}</td>
                                  <td style={{textAlign: 'center'}}><button title="Play" className='playButtonReport' onClick={() => window.open(`https://input-audio-file.s3.us-east-2.amazonaws.com/${list.audiofile}`)}><i className="fa fa-play playColor"></i></button></td>
                                  <td style={{textAlign: 'center'}}><i onClick={() => handleShowModalOne(list)} className="fa fa-eye"></i></td>
                                  {/* <td style={{textAlign: 'center'}}><button title="Download" className='playButtonReport' onClick={() => window.location.href=`https://trascribe-result.s3.us-east-2.amazonaws.com/${list.audiofile.slice(0, list.audiofile.length-4)}`}><i className="fa fa-lg fa-download playColor" aria-hidden="true"></i></button></td> */}
                                  {/* <td style={{textAlign: 'center'}}>{list.status === 0 ? "Pending": list.status === 2 ? "N/A" : <button title="Download" className='playButtonReport' onClick={() => window.location.href=`https://abrt.dynamicwebsite.co.in/ns/ABRTL/public/api/download-bow/${list.id}`}><i className="fa fa-lg fa-download playColor" aria-hidden="true"></i></button>}</td> */}
                                </tr> 
                              )
                            })
                            :
                            <tr>
                              <td colSpan='7'>
                                <div className="RecordNotFound"> No Record Found</div>
                              </td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  <Modal show={modalState === "modal-two"} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Level Chart</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <DoughnutChart data={donughtData} />
                      {/* <Chart data={donughtData} /> */}
                    </Modal.Body>
                  </Modal>


                  <Modal show={modalState === "modal-one"} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>More Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="profile-right-panel">
                      <div className="profile-info-table">
                        <div className="profile-info-row">
                          <div className="profile-label">
                            Grade
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.grade}
                            {/* {profileData?.userdata?.first_name} {profileData?.userdata?.last_name} */}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                            Subject
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                          {listModal.subject}
                            {/* {profileData?.userdata?.grade} */}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                          Expected Student Outcome
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.expected_student_outcome}
                            {/* {profileData?.userdata?.school_name} */}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                            Lesson Predicted Level
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.predicted_level}
                            {/* {profileData?.userdata?.district_name} */}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                          Learning Objective
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.learning_objective}
                            {/* {profileData?.userdata?.phone_number} */}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                          Lesson Description
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.lesson_description}
                            {/* {profileData?.avgLevelNo} */}
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    </Modal.Body>
                  </Modal>
                  

                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'next'}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={'paginationBttns pagetest'}
                    previousLinkClassName={data?.total <= 10 && 'previousBttn'}
                    nextLinkClassName={data?.total <= 10 && 'nextBttn'}
                    disabledClassName={'paginationDisabled'}
                    activeClassName={data?.total <= 10 && 'paginationActive'}
                    breakLabel={'...'}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default TeacherReport
