import React, { useState, useEffect } from 'react';
import TeacherHeader from './TeacherHeader';
import Footer from '../Footer';
import axios from 'axios';
import { baseUrl } from '../../config/config';
import ApiLoader from '../ApiLoader';

function PreviousLevel() {
	const [data, setData] = useState();
	const [loader, setLoader] = useState(true);

	useEffect(() => {
		const token = localStorage.getItem('token');
		axios.get(baseUrl + 'previous-level', { headers: { "Authorization": `Bearer ${token}` } })
			.then((res) => {
				setData(res.data.data);
				setLoader(false);
				console.log('previous level', res.data.data);
			})
	}, [])

	return (
		<>
			{loader && <ApiLoader />}
			<TeacherHeader />
			<div className="dashboard-cover">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="dashboard-title-cover">
								<h1>Previous Average Level</h1>
							</div>

							<div className="table-responsive">
								<table className="table testing">
									<thead>
										<tr>
											<th scope="col">Years</th>
											<th scope="col">Average Level</th>
										</tr>
									</thead>
									<tbody>
										{
											data?.lenght !== 0
												?
												data?.map((item) => {
													return (
														<tr>
															<td>{item.years}</td>
															<td>{Math.round(item.totalsubmissions)}</td>
														</tr>
													)
												})
												:
												<td colSpan='7'>
													<div className="RecordNotFound"> No Record Found</div>
												</td>
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

				</div>
			</div>
			<Footer />
		</>
	)
}

export default PreviousLevel