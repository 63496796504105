import React from 'react'
import Login from './Login'

function index() {
    return (
        <div class="register-wrapper">
            <Login />
        </div>
    )
}

export default index
