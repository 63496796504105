import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../../config/config';
// import profile from '../../assets/images/profile.png';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom';

function ProfileDropdown() {
  const [data, setData] = useState([]);
  const [subMenu, setSubMenu] = useState(false);
  const [subMenuList, setSubMenuList] = useState([]);

  const logout = () => {
    const token = localStorage.getItem('token');
    axios.get(baseUrl + 'logout', { headers: { "Authorization": `Bearer ${token}` } })
    localStorage.removeItem('token');
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(baseUrl + 'dashboard', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        setData(res.data.data.userdata);
        // console.log('dashboard',res.data.data.userdata);
      })
    axios.get(baseUrl + 'menu-list', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        setSubMenuList(res.data.data);
        // console.log('submenu list', res.data.data);
      })

  }, [])

  return (
    <>
      <div className="dropdown-cover ml-auto">
        <Dropdown onClick={() => setSubMenu(false)}>
          <Dropdown.Toggle
            className='header-profile'
            style={{
              expanded: 'false !important'
            }}>


            {localStorage.getItem('token')
              ? <span className="profile-img">
                {data?.profile_picture && data?.profile_picture_path !== null
                  ? <img src={data?.profile_picture_path + data?.profile_picture} alt="" />
                  : <i className="fa fa-user fa-2x" />
                }
              </span>
              : ''
            }
            {/* <span className="dropdown-text">Hello {data?.first_name}</span> */}
            <span className="dropdown-text">Menu</span>
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ width: '100%' }} className={localStorage.getItem('token') ? "" : "dropdownHeight"}>
            {localStorage.getItem('token') ? <Dropdown.Item><Link style={{ color: 'black', textDecoration: 'none', display: 'block' }} to={'/teacher-profile'}>View Profile</Link></Dropdown.Item> : ''}
            <div className={`hoverMenu ${subMenu ? 'subMenuActive' : ''}`}>
              <Dropdown.Item><Link onClick={(e) => { e.stopPropagation(); setSubMenu(!subMenu) }} style={{ color: 'black', textDecoration: 'none', display: 'block' }}>Resources <i className="subMenuIcon fa fa-chevron-right"> </i> </Link></Dropdown.Item>

              <div className="subMenuHover">
                {
                  subMenuList.map((list, index) => {
                    return (
                      <Dropdown.Item><Link style={{ color: 'black', textDecoration: 'none', display: 'block' }} to={`/page/${list.key}`}>{list.title}</Link></Dropdown.Item>
                    )
                  })
                }
                <Dropdown.Item><Link style={{ color: 'black', textDecoration: 'none', display: 'block' }} to={'/teacher-faq'}>FAQ's</Link></Dropdown.Item>


                {/* <Dropdown.Item><Link style={{ color: 'black', textDecoration: 'none', display: 'block' }} to={'/ns/ABRTL-WEB/teacher-aboutus'}>About Us</Link></Dropdown.Item>
                <Dropdown.Item><Link style={{ color: 'black', textDecoration: 'none', display: 'block' }} to={'/ns/ABRTL-WEB/teacher-termsCondition'}>Terms & Condition</Link></Dropdown.Item>
                // <Dropdown.Item><Link style={{color: 'black', textDecoration: 'none', padding: '10px 90px 10px 0px'}}to={'/ns/ABRTL-WEB/teacher-contactus'}>Contact Us</Link></Dropdown.Item> 
                <Dropdown.Item><Link style={{ color: 'black', textDecoration: 'none', display: 'block' }} to={'/ns/ABRTL-WEB/teacher-faq'}>FAQ's</Link></Dropdown.Item> */}
              </div>
            </div>


            {localStorage.getItem('token')
            ? <Dropdown.Item onClick={logout}><Link style={{ color: 'black', textDecoration: 'none', padding: '10px 125px 10px 0px' }} to={'/'}>Logout</Link></Dropdown.Item>
            :  <Dropdown.Item onClick={logout}><Link style={{ color: 'black', textDecoration: 'none', padding: '10px 125px 10px 0px' }} to={'/'}>Login</Link></Dropdown.Item>
            }

          </Dropdown.Menu>
        </Dropdown>

        {/* <i onClick={() => setSubMenu(!subMenu)} className="fa fa-play " /> */}
        {/* {subMenu && ( */}
        {/* //   <>   */}
        {/* //     <Dropdown.Item><Link style={{color: 'black', textDecoration: 'none', padding: '10px 110px 10px 0px'}}to={'/ns/ABRTL-WEB/teacher-aboutus'}>About Us</Link></Dropdown.Item>   */}
        {/* //     <Dropdown.Item><Link style={{color: 'black', textDecoration: 'none', padding: '10px 48px 10px 0px'}}to={'/ns/ABRTL-WEB/teacher-termsCondition'}>Terms & Condition</Link></Dropdown.Item> */}
        {/* //     <Dropdown.Item><Link style={{color: 'black', textDecoration: 'none', padding: '10px 90px 10px 0px'}}to={'/ns/ABRTL-WEB/teacher-contactus'}>Contact Us</Link></Dropdown.Item> */}
        {/* //     <Dropdown.Item><Link style={{color: 'black', textDecoration: 'none', padding: '10px 130px 10px 0px'}}to={'/ns/ABRTL-WEB/teacher-faq'}>FAQ's</Link></Dropdown.Item>  */}
        {/* // </> */}
        {/* // )} */}

      </div>

    </>
  )
}

export default ProfileDropdown
